import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import ChangeLogContent from "../ChangeLogContent"
import PresetChart from "src/pages/Presets/PresetChart"
import { parseFromDcu } from "src/pages/Dashboard/DeviceInfo/dcu.service"
//0 - 255 -> 0 - 100
const lookupDALI = (v) => {
    const valueToParse = v <= 255 ? v : 255
    return {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
        31: 0,
        32: 0,
        33: 0,
        34: 0,
        35: 0,
        36: 0,
        37: 0,
        38: 0,
        39: 0,
        40: 0,
        41: 0,
        42: 0,
        43: 0,
        44: 0,
        45: 0,
        46: 0,
        47: 0,
        48: 0,
        49: 0,
        50: 0,
        51: 0,
        52: 0,
        53: 0,
        54: 0,
        55: 0,
        56: 0,
        57: 0,
        58: 0,
        59: 0,
        60: 1,
        61: 1,
        62: 1,
        63: 1,
        64: 1,
        65: 1,
        66: 1,
        67: 1,
        68: 1,
        69: 1,
        70: 1,
        71: 1,
        72: 1,
        73: 1,
        74: 1,
        75: 1,
        76: 1,
        77: 1,
        78: 1,
        79: 1,
        80: 1,
        81: 1,
        82: 1,
        83: 1,
        84: 1,
        85: 1,
        86: 1,
        87: 1,
        88: 1,
        89: 1,
        90: 1,
        91: 1,
        92: 1,
        93: 1,
        94: 1,
        95: 1,
        96: 1,
        97: 1,
        98: 1,
        99: 1,
        100: 1,
        101: 2,
        102: 2,
        103: 2,
        104: 2,
        105: 2,
        106: 2,
        107: 2,
        108: 2,
        109: 2,
        110: 2,
        111: 2,
        112: 2,
        113: 2,
        114: 2,
        115: 2,
        116: 2,
        117: 2,
        118: 2,
        119: 3,
        120: 3,
        121: 3,
        122: 3,
        123: 3,
        124: 3,
        125: 3,
        126: 3,
        127: 3,
        128: 3,
        129: 3,
        130: 3,
        131: 3,
        132: 4,
        133: 4,
        134: 4,
        135: 4,
        136: 4,
        137: 4,
        138: 4,
        139: 4,
        140: 4,
        141: 5,
        142: 5,
        143: 5,
        144: 5,
        145: 5,
        146: 5,
        147: 5,
        148: 6,
        149: 6,
        150: 6,
        151: 6,
        152: 6,
        153: 6,
        154: 7,
        155: 7,
        156: 7,
        157: 7,
        158: 7,
        159: 7,
        160: 8,
        161: 8,
        162: 8,
        163: 8,
        164: 9,
        165: 9,
        166: 9,
        167: 9,
        168: 10,
        169: 10,
        170: 10,
        171: 10,
        172: 11,
        173: 11,
        174: 11,
        175: 12,
        176: 12,
        177: 12,
        178: 13,
        179: 13,
        180: 13,
        181: 14,
        182: 14,
        183: 14,
        184: 15,
        185: 15,
        186: 16,
        187: 16,
        188: 17,
        189: 17,
        190: 17,
        191: 18,
        192: 18,
        193: 19,
        194: 19,
        195: 20,
        196: 21,
        197: 21,
        198: 22,
        199: 22,
        200: 23,
        201: 24,
        202: 24,
        203: 25,
        204: 26,
        205: 26,
        206: 27,
        207: 28,
        208: 28,
        209: 29,
        210: 30,
        211: 31,
        212: 32,
        213: 33,
        214: 34,
        215: 34,
        216: 35,
        217: 36,
        218: 37,
        219: 38,
        220: 40,
        221: 41,
        222: 42,
        223: 43,
        224: 44,
        225: 45,
        226: 47,
        227: 48,
        228: 50,
        229: 51,
        230: 52,
        231: 53,
        232: 55,
        233: 56,
        234: 58,
        235: 60,
        236: 61,
        237: 63,
        238: 65,
        239: 66,
        240: 68,
        241: 70,
        242: 72,
        243: 74,
        244: 76,
        245: 78,
        246: 80,
        247: 83,
        248: 85,
        249: 87,
        250: 90,
        251: 92,
        252: 95,
        253: 97,
        254: 100,
    }[valueToParse]
}

const DcuVisualisation = ({ t, contentData }: any) => {
    if (
        !contentData ||
        !Array.isArray(contentData) ||
        !Array.isArray(contentData[0])
    ) {
        return <strong className="text-dark">{t("No data")}</strong>
    }
    const contentDataParsed = contentData[0].map((elem) => {
        return {
            time: elem["TIME"],
            value: lookupDALI(elem["DIMM"] ?? 0),
        }
    })
    return (
        <PresetChart
            t={t}
            height="200"
            data={parseFromDcu(contentDataParsed)}
        />
    )
}

const ChangeLogDcu = (props: any) => {
    const subTitle = props.t("Pending changes")
    document.title = `${subTitle} | ${props.t("TITLE")}`

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={subTitle}
                    breadcrumbItem={props.t("Dimming Curve")}
                />
                <ChangeLogContent
                    t={props.t}
                    type="config"
                    title="List of pending dimming curve changes"
                    apiUrl="/api/pending-dcu"
                    displayBlobHandler={DcuVisualisation}
                />
            </Container>
        </div>
    )
}

export default withTranslation()(ChangeLogDcu)
