import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    Row,
} from "reactstrap"
import ErrorHttp from "../ErrorHttp"
import { Spinner } from "react-awesome-spinners"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { createAuthHeader } from "../../helpers/logged_in_user"
import { BlobContent } from "./BlobContent"
import { checkPermissions, haspermAdmin } from "src/helpers/permission_check"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"

export interface blobname {
    name: string
}

const Blob = ({ name }: blobname) => {
    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <BlobContent name={name} />
            </div>
        </div>
    )
}

const MessagesContent = (props: any) => {
    const [hasPermissions, setHasPermissions] = useState(true)

    const abortControllerRef = useRef<AbortController>()

    if (!hasPermissions) {
        checkPermissions(haspermAdmin, setHasPermissions)
    }

    const {
        isLoading,
        data: blobsList,
        error,
    } = useFetchWithAbort(
        `/api/list-blobs`,
        {
            ...createAuthHeader("admin"),
        },
        abortControllerRef
    )

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }
        }
    }, [])

    if (isLoading) return <Spinner />

    if (!hasPermissions) {
        return <ErrorHttp code="403" permission="admin" />
    }

    if (error) return <ErrorHttp code="500" />

    return (
        <>
            <Row>
                <Card>
                    <CardHeader>
                        <CardTitle className="h4">
                            {props.t("BlobsListTitle")}
                        </CardTitle>
                        <p className="card-title-desc">
                            {props.t("BlobsListDesc")}
                        </p>
                    </CardHeader>
                    <CardBody>
                        {blobsList
                            .reverse()
                            .map((oneBlob: any, index: number) => (
                                <Blob key={index} name={oneBlob} />
                            ))}
                    </CardBody>
                </Card>
            </Row>
        </>
    )
}

const Messages = (props: any) => {
    const subTitle = props.t("RawData")
    document.title = `${props.t("RawData")} | ${props.t("TITLE")}`

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={subTitle}
                    breadcrumbItem={props.t("Messages")}
                />
                <MessagesContent t={props.t} />
            </Container>
        </div>
    )
}

export default withTranslation()(Messages)
