import React from "react"

import {
    Row,
    Col,
    Alert,
    Container,
    FormFeedback,
    Input,
    Label,
    Form,
} from "reactstrap"

import PropTypes from "prop-types"
//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import withRouter from "../Common/withRouter";
import withRouter from "../../components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userChangePassword } from "../../store/actions"
import { withTranslation } from "react-i18next"

interface ChangePasswordProps {
    history: object
}

const ChangePasswordPage = (props: any, { history }: ChangePasswordProps) => {
    const dispatch = useDispatch()

    const { changeError, changeSuccessMsg } = useSelector((state: any) => ({
        changeError: state.changePassword.changeError,
        changeSuccessMsg: state.changePassword.changeSuccessMsg,
    }))

    const { userName } = JSON.parse(localStorage.getItem("authUser"))

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            userName: userName,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            userName: Yup.string().required("Please Enter Your userName"),
            oldPassword: Yup.string().required("Please Enter Your oldPassword"),
            newPassword: Yup.string()
                .required("Please Enter Your newPassword")
                .min(4, "Password must be at least 4 characters long")
                .oneOf([Yup.ref("confirmPassword")], "Passwords must match"),
            confirmPassword: Yup.string()
                .required("Please Enter Your confirmPassword")
                .oneOf([Yup.ref("newPassword")], "Passwords must match"),
        }),
        onSubmit: (values) => {
            dispatch(userChangePassword(values, history))
        },
    })

    document.title = `Change Password | ${props.t("TITLE")}`

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <div className="auth-full-page-content d-flex p-sm-5 p-4">
                        <div className="w-100">
                            <div className="d-flex flex-column h-100">
                                <div className="auth-content my-auto">
                                    <div className="text-center">
                                        <h5 className="mb-0">
                                            {props.t("ResetPassword")}
                                        </h5>
                                    </div>

                                    {changeError && changeError ? (
                                        <Alert
                                            color="danger"
                                            style={{
                                                marginTop: "13px",
                                            }}
                                        >
                                            {changeError}
                                        </Alert>
                                    ) : null}
                                    {changeSuccessMsg ? (
                                        <Alert
                                            color="success"
                                            style={{
                                                marginTop: "13px",
                                            }}
                                        >
                                            {changeSuccessMsg}
                                        </Alert>
                                    ) : null}

                                    <Form
                                        className="custom-form mt-4"
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >
                                        <Input
                                            name="userName"
                                            className="form-control"
                                            type="hidden"
                                            value={userName}
                                        />
                                        <Label className="form-label">
                                            {props.t("userName")}: {userName}
                                        </Label>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {props.t("OldPassword")}
                                            </Label>
                                            <Input
                                                name="oldPassword"
                                                className="form-control"
                                                placeholder={props.t(
                                                    "EnterOldPassword"
                                                )}
                                                type="password"
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values
                                                        .oldPassword || ""
                                                }
                                                invalid={
                                                    validation.touched
                                                        .oldPassword &&
                                                    validation.errors
                                                        .oldPassword
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.oldPassword &&
                                            validation.errors.oldPassword ? (
                                                <FormFeedback type="invalid">
                                                    {
                                                        validation.errors
                                                            .oldPassword
                                                    }
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {props.t("NewPassword")}
                                            </Label>
                                            <Input
                                                name="newPassword"
                                                className="form-control"
                                                placeholder={props.t(
                                                    "EnterNewPassword"
                                                )}
                                                type="password"
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values
                                                        .newPassword || ""
                                                }
                                                invalid={
                                                    validation.touched
                                                        .newPassword &&
                                                    validation.errors
                                                        .newPassword
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.newPassword &&
                                            validation.errors.newPassword ? (
                                                <FormFeedback type="invalid">
                                                    {
                                                        validation.errors
                                                            .newPassword
                                                    }
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {props.t("ConfirmPassword")}
                                            </Label>
                                            <Input
                                                name="confirmPassword"
                                                className="form-control"
                                                placeholder={props.t(
                                                    "EnterConfirmPassword"
                                                )}
                                                type="password"
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values
                                                        .confirmPassword || ""
                                                }
                                                invalid={
                                                    validation.touched
                                                        .confirmPassword &&
                                                    validation.errors
                                                        .confirmPassword
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched
                                                .confirmPassword &&
                                            validation.errors
                                                .confirmPassword ? (
                                                <FormFeedback type="invalid">
                                                    {
                                                        validation.errors
                                                            .confirmPassword
                                                    }
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                        <Row className="mb-3">
                                            <Col className="text-end">
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    {props.t("Reset")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(withRouter(ChangePasswordPage))

ChangePasswordPage.propTypes = {
    //history: PropTypes.object,
    t: PropTypes.any,
}
