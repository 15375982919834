/* extending useFetch from react-fetch-hook to allow for aborting API calls */
import { MutableRefObject } from "react"
import useFetch from "react-fetch-hook"
import config from "src/config"

const { fetch: originalFetch } = window

export const useFetchWithAbort = (
    endpoint: RequestInfo,
    options?: useFetch.HookOptions | useFetch.HookOptionsWithFormatter<any>,
    abortController?: MutableRefObject<any>
): useFetch.FetchResult<any> => {
    if (!config.isFakeBackend) {
        // not applicable if app is run with fake backend
        window.fetch = async (...args) => {
            let [resource, config] = args
            //we are adding abort control to original fetch call so we can abort it!
            if (abortController) {
                abortController.current = new AbortController()
                config = { signal: abortController.current.signal, ...config }
            }
            const response = await originalFetch(resource, config)
            return response
        }
    }

    return useFetch(endpoint, options)
}
