import React, { useEffect, useRef, useState } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"
import MapVectorLayers from "./LeafletMap/MapVectorLayers"
import DatatableTables from "./DataTable/DatatableTables"
import DeviceInfo from "./DeviceInfo/DeviceInfo"
import ErrorHttp from "../ErrorHttp"
import classnames from "classnames"
import {
    haspermDisplayDevices,
    checkPermissions,
} from "../../helpers/permission_check"
import { Spinner } from "react-awesome-spinners"
import { createAuthHeader } from "../../helpers/logged_in_user"
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { formatDateToLocalTime } from "../../helpers/format_date"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"

const getDeviceOgrId = (
    devicesData: Array<any>,
    rowId: number | null
): string | null => {
    if (devicesData != null && rowId != null) {
        return devicesData[rowId].id ?? "?"
    }
    return null
}

const getDeviceId = (
    devicesData: Array<any>,
    rowId: number | null
): string | null => {
    if (devicesData != null && rowId != null) {
        return devicesData[rowId]?.device ?? null
    }
    return null
}

const getCurrentAddress = (
    devicesData: Array<any>,
    rowId: number | null
): string | null => {
    if (devicesData != null && rowId != null) {
        return devicesData[rowId]?.location ?? null
    }
    return null
}

const parseDevicesDataForDisplay = (devicesData: Array<any>) => {
    return devicesData.map((oneDevice: any, index: number) => {
        return {
            id: oneDevice["id"],
            device: oneDevice["device"],
            location: oneDevice["location"],
            status: oneDevice?.online ? "online" : "offline",
            online: oneDevice.online,
            lastSeen: formatDateToLocalTime(oneDevice["lastSeen"]),
            lat: oneDevice["lat"],
            lon: oneDevice["lon"],
        }
    })
}

const DashboardContent = (props: any) => {
    const [selectedDeviceRowId, setSelectedDeviceRowId] = useState<
        number | null
    >(null)
    const [showDetails, setShowDetails] = useState<boolean>(false)

    const [hasPermissionsDisplayDevices, setHasPermissionsDisplayDevices] =
        useState(false)

    const abortControllerRefDevices = useRef<AbortController>()

    const abortControllerRefPresets = useRef<AbortController>()

    if (!hasPermissionsDisplayDevices) {
        checkPermissions(haspermDisplayDevices, setHasPermissionsDisplayDevices)
    }

    const {
        isLoading,
        data: devicesData,
        error,
    } = useFetchWithAbort(
        `/api/devices`,
        {
            ...createAuthHeader("displayDevices"),
        },
        abortControllerRefDevices
    )

    const {
        data: presets,
        isLoading: isLoadingPresets,
        error: errorLoadingPresets,
    } = useFetchWithAbort(
        `/api/presets`,
        {
            ...createAuthHeader("displayDevices"),
        },
        abortControllerRefPresets
    )

    useEffect(() => {
        return () => {
            if (abortControllerRefDevices.current) {
                abortControllerRefDevices.current.abort()
            }
            if (abortControllerRefPresets.current) {
                abortControllerRefPresets.current.abort()
            }
        }
    }, [])

    if (isLoading || isLoadingPresets) {
        return <Spinner />
    }

    if (!hasPermissionsDisplayDevices) {
        return <ErrorHttp code="403" permission="displayDevices" />
    }

    if (error || errorLoadingPresets) {
        return <ErrorHttp code="500" />
    }

    const deviceOgrId = getDeviceOgrId(devicesData, selectedDeviceRowId)

    const deviceIotId = getDeviceId(devicesData, selectedDeviceRowId)

    const currentAddress = getCurrentAddress(devicesData, selectedDeviceRowId)

    const devicesDisplayData = parseDevicesDataForDisplay(devicesData)

    return (
        <>
            <Row>
                <Col lg="6">
                    <MapVectorLayers
                        devicesData={devicesDisplayData}
                        selectedDeviceRowId={selectedDeviceRowId}
                        callbackPointSelection={setSelectedDeviceRowId}
                        t={props.t}
                    />
                </Col>
                <Col lg="6">
                    <Card>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: !showDetails,
                                    })}
                                    onClick={() => {
                                        setShowDetails(false)
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        {props.t("List of devices")}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: showDetails,
                                    })}
                                    onClick={() => {
                                        setShowDetails(true)
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        {props.t("Details")}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        {showDetails ? (
                            <DeviceInfo
                                addressFromDevice={currentAddress}
                                deviceId={deviceIotId}
                                deviceOgrId={deviceOgrId}
                                presets={presets}
                                t={props.t}
                            />
                        ) : (
                            <DatatableTables
                                devicesData={devicesDisplayData}
                                selectedDeviceRowId={selectedDeviceRowId}
                                callbackRowSelection={setSelectedDeviceRowId}
                                t={props.t}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const Dashboard = (props: any) => {
    document.title = `${props.t("Dashboard")} | ${props.t("TITLE")}`
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={props.t("Dashboard")}
                    breadcrumbItem={props.t("Devices")}
                />
                <DashboardContent t={props.t} />
            </Container>
        </div>
    )
}

export default withTranslation()(Dashboard)
