import React, { useEffect, useRef, useState } from "react"
import { Container, Label } from "reactstrap"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import ErrorHttp from "../../ErrorHttp"
import { Spinner } from "react-awesome-spinners"

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import DatatableTables from "../DatatableTables"

import {
    isoYesterday,
    isoWeekAgo,
} from "src/pages/Dashboard/DeviceInfo/dcu.service"
import MailingResult from "../MailingResult"

import { createAuthHeader } from "../../../helpers/logged_in_user"
import {
    checkPermissions,
    haspermDisplayReports,
} from "../../../helpers/permission_check"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"

const fixedHeader = [
    {
        Header: "Device Id",
        accessor: "id",
        id: "id",
    },
    {
        Header: "Device URN",
        accessor: "device",
        id: "device",
    },
    {
        Header: "Address",
        accessor: "address",
        id: "address",
    },
    {
        Header: "GPS",
        accessor: "location",
        id: "location",
    },
    {
        Header: "Manufacturer",
        accessor: "manufacturer",
        id: "manufacturer",
    },
    {
        Header: "Nominal Power",
        accessor: "power",
        id: "power",
    },
    {
        Header: "Description",
        accessor: "description",
        id: "description",
    },
    {
        Header: "Work time [min]",
        accessor: "workTime",
        id: "workTime",
    },
    {
        Header: "On time [min]",
        accessor: "onTime",
        id: "onTime",
    },
    {
        Header: "Energy usage [Wh]",
        accessor: "usage",
        id: "usage",
    },
]

const ReportsContent = (props: any) => {
    const [dateFrom, setDateFrom] = useState<string>(isoWeekAgo)

    const [dateTo, setDateTo] = useState<string>(isoYesterday)

    const [emailStatusResult, setEmailStatusResult] = useState<any>(false)
    const [emailStatusLoading, setEmailStatusLoading] = useState<any>(false)
    const [emailStatusError, setEmailStatusError] = useState<any>(false)

    const [hasPermissionsDisplayReports, setHasPermissionsDisplayReports] =
        useState(false)

    const abortControllerRef = useRef<AbortController>()

    if (!hasPermissionsDisplayReports) {
        checkPermissions(haspermDisplayReports, setHasPermissionsDisplayReports)
    }

    const { isLoading, data, error } = useFetchWithAbort(
        `/api/reports-work?from=${dateFrom}&to=${dateTo}`,
        {
            ...createAuthHeader("displayReports"),
        },
        abortControllerRef
    )

    const triggerMailing = (
        _emailStatusResult: any,
        _emailStatusLoading: any,
        _emailStatusError: any
    ) => {
        setEmailStatusError(_emailStatusError)
        setEmailStatusLoading(_emailStatusLoading)
        setEmailStatusResult(_emailStatusResult)
    }

    useEffect(() => {
        triggerMailing(false, false, false)
    }, [dateFrom, dateTo])

    useEffect(
        () => () => {
            if (abortControllerRef.current) {
                console.log("Aborting API calls WorkingReport")
                abortControllerRef.current.abort()
            }
        },
        []
    )

    if (isLoading) {
        return <Spinner />
    }

    if (!hasPermissionsDisplayReports) {
        return <ErrorHttp code="403" permission="displayReports" />
    }

    if (error) {
        return <ErrorHttp code="500" />
    }

    const info = {
        headers: fixedHeader,
    }

    const dateChange = (selectedDates: any, dateStr: any) => {
        if (!selectedDates || selectedDates.length != 2) {
            console.log(`No date changed: ${dateStr}`)
            return
        }
        const dateFrom = selectedDates[0].toISOString()
        const dateTo = selectedDates[1].toISOString()
        setDateFrom(dateFrom)
        setDateTo(dateTo)
    }

    return (
        <>
            <Label>{props.t("Report for a period")}</Label>
            <MailingResult
                result={emailStatusResult}
                isSending={emailStatusLoading}
                error={emailStatusError}
                t={props.t}
            />
            <Flatpickr
                onChange={dateChange}
                className="form-control d-block"
                placeholder="yyyy-mm-dd"
                options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                    enableTime: false,
                    defaultDate: [dateFrom, dateTo],
                }}
            />
            <DatatableTables
                data={data}
                info={info}
                title={props.t("Device work report")}
                t={props.t}
                mailHandler={triggerMailing}
                mailUrl={`/api/email-work?from=${dateFrom}&to=${dateTo}`}
            />
        </>
    )
}

const Reports = (props: any) => {
    const subTitle = props.t("Reports")
    document.title = `${subTitle} | ${props.t("TITLE")}`

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={subTitle}
                    breadcrumbItem={props.t("Working")}
                />
                <ReportsContent t={props.t} />
            </Container>
        </div>
    )
}

export default withTranslation()(Reports)
