import React, { useEffect, useState } from "react"
import { Collapse, Spinner, Alert } from "reactstrap"
import LoadingErrorHttp from "../LoadingErrorHttp"
import { createAuthHeader } from "../../helpers/logged_in_user"

//i18n
import { withTranslation } from "react-i18next"

import classnames from "classnames"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"

const BlobContent = ({
    content,
    t,
    reloadParentHandler,
    type,
    displayBlobHandler,
}) => {
    const [refreshPostKey, setRefreshPostKey] = useState<number>(0)
    const [refreshDeleteKey, setRefreshDeleteKey] = useState<number>(0)
    const [opened, setOpened] = useState(false)
    const [deleteBlob, setDeleteBlob] = useState<boolean>(null)
    const [postBlob, setPostBlob] = useState<boolean>(null)

    const blobContentPermission = "admin"

    const blobName = content?.name ?? ""

    const authAdminHeader = createAuthHeader(blobContentPermission)

    const blobNameParam = encodeURIComponent(blobName)
    const {
        isLoading,
        data: blobContent,
        error,
    } = useFetchWithAbort(
        `/api/blob?name=${blobNameParam}&type=${type}`,
        authAdminHeader
    )

    const {
        data: deleteResult,
        isLoading: isDeleting,
        error: errorDelete,
    } = useFetchWithAbort(
        `/api/blob?name=${blobNameParam}&type=${type}&refreshKey=${refreshDeleteKey}`,
        {
            method: "DELETE",
            depends: [deleteBlob],
            ...authAdminHeader,
        }
    )

    const {
        data: postResult,
        isLoading: isPosting,
        error: errorPost,
    } = useFetchWithAbort(
        `/api/blob?name=${blobNameParam}&type=${type}&refreshKey=${refreshPostKey}`,
        {
            method: "POST",
            body: JSON.stringify(blobContent) ?? "",
            depends: [postBlob],
            ...authAdminHeader,
        }
    )

    const setDeleteEvent = () => {
        setDeleteBlob(true)
        setRefreshDeleteKey((prevKey) => prevKey + 1)
    }

    const setPostEvent = () => {
        setPostBlob(true)
        setRefreshPostKey((prevKey) => prevKey + 1)
    }

    useEffect(() => {
        if (!!deleteResult && !isLoading && !errorDelete) {
            reloadParentHandler()
            setDeleteBlob(false)
        }
    }, [deleteBlob, deleteResult, isDeleting, errorDelete])

    useEffect(() => {
        if (!!postResult && !isPosting && !errorPost) {
            //reloadParentHandler() - no refreshing of all commands
            setPostBlob(false)
        }
    }, [postBlob, postResult, isPosting, errorPost])

    if (isLoading || isDeleting || isPosting) return <Spinner />

    if (error?.status == 403) {
        return (
            <LoadingErrorHttp code="403" permission={blobContentPermission} />
        )
    }

    if (error) {
        return <LoadingErrorHttp code={error.status ?? `500`} />
    }

    const deviceId = content?.deviceId ?? ""

    const creationDate = content?.creationDate ?? ""

    return (
        <>
            <h2 className="accordion-header" id="headingOne">
                <button
                    className={classnames("accordion-button", "fw-medium", {
                        collapsed: !opened,
                    })}
                    type="button"
                    onClick={() => {
                        setOpened(!opened)
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <strong>{t("Device")}:</strong>
                    <span>{deviceId}</span>&nbsp;
                    <strong>{t("Change request time")}:</strong>
                    <span>{creationDate}</span>
                </button>
            </h2>

            <Collapse isOpen={opened} className="accordion-collapse">
                <div className="accordion-body">
                    <div className="text-muted">
                        {displayBlobHandler ? (
                            displayBlobHandler({ t, contentData: blobContent })
                        ) : (
                            <strong className="text-dark">
                                {JSON.stringify(blobContent) ?? t("No data")}
                            </strong>
                        )}
                    </div>
                    <hr></hr>
                    {errorDelete ? (
                        <Alert color="danger">
                            {t("Error deleting command for device")}:&nbsp;
                            {deviceId}
                        </Alert>
                    ) : null}
                    {errorPost ? (
                        <Alert color="danger">
                            {t("Error sending command for device")}:&nbsp;
                            {deviceId}
                        </Alert>
                    ) : null}
                    <div className="d-flex flex-wrap gap-2">
                        {blobContent ? (
                            <>
                                <button
                                    type="button"
                                    onClick={setPostEvent}
                                    className="btn btn-success waves-effect btn-label waves-light"
                                >
                                    <i className="bx bx-check-double label-icon"></i>
                                    {t("Send request")}
                                </button>
                                <button
                                    type="button"
                                    onClick={setDeleteEvent}
                                    className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                    <i className="bx bx-trash label-icon"></i>
                                    {t("Delete request")}
                                </button>
                            </>
                        ) : null}
                    </div>
                </div>
            </Collapse>
        </>
    )
}

const Blob = (props: any) => {
    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <BlobContent
                    content={props.content}
                    t={props.t}
                    type={props.type}
                    reloadParentHandler={props.reloadParentHandler}
                    displayBlobHandler={props.displayBlobHandler}
                />
            </div>
        </div>
    )
}

export default withTranslation()(Blob)
