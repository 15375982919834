import React, { useEffect, useRef, useState } from "react"
import { Container, Label } from "reactstrap"
import ErrorHttp from "../../ErrorHttp"
import { Spinner } from "react-awesome-spinners"

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import DatatableTables from "../DatatableTables"
import MailingResult from "../MailingResult"

import { createAuthHeader } from "../../../helpers/logged_in_user"
import {
    checkPermissions,
    haspermDisplayReports,
} from "../../../helpers/permission_check"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"

const fixedHeader = [
    {
        Header: "Device Id",
        accessor: "id",
        id: "id",
    },
    {
        Header: "Device URN",
        accessor: "device",
        id: "device",
    },
    {
        Header: "Address",
        accessor: "address",
        id: "address",
    },
    {
        Header: "GPS",
        accessor: "location",
        id: "location",
    },
    {
        Header: "Manufacturer",
        accessor: "manufacturer",
        id: "manufacturer",
    },
    {
        Header: "Nominal Power",
        accessor: "power",
        id: "power",
    },
    {
        Header: "Description",
        accessor: "description",
        id: "description",
    },
    {
        Header: "Last seen",
        accessor: "lastSeen",
        id: "lastSeen",
    },
    {
        Header: "Last report",
        accessor: "lastDataReport",
        id: "lastDataReport",
    },
    {
        Header: "On/off",
        accessor: "online",
        id: "online",
    },
    {
        Header: "On time [min]",
        accessor: "totalOnTime",
        id: "totalOnTime",
    },
]

const ReportsContent = (props: any) => {
    const [emailStatusResult, setEmailStatusResult] = useState<any>(false)
    const [emailStatusLoading, setEmailStatusLoading] = useState<any>(false)
    const [emailStatusError, setEmailStatusError] = useState<any>(false)

    const [hasPermissionsDisplayReports, setHasPermissionsDisplayReports] =
        useState(false)

    const abortControllerRef = useRef<AbortController>()

    if (!hasPermissionsDisplayReports) {
        checkPermissions(haspermDisplayReports, setHasPermissionsDisplayReports)
    }

    const { isLoading, data, error } = useFetchWithAbort(
        `/api/reports-status`,
        {
            ...createAuthHeader("displayReports"),
        },
        abortControllerRef
    )

    useEffect(
        () => () => {
            if (abortControllerRef.current) {
                console.log("Aborting API calls StatusReport")
                abortControllerRef.current.abort()
            }
        },
        []
    )

    if (isLoading) {
        return <Spinner />
    }

    if (!hasPermissionsDisplayReports) {
        return <ErrorHttp code="403" permission="displayReports" />
    }

    if (error) {
        return <ErrorHttp code="500" />
    }

    const info = {
        headers: fixedHeader,
    }

    const triggerMailing = (
        _emailStatusResult: any,
        _emailStatusLoading: any,
        _emailStatusError: any
    ) => {
        setEmailStatusError(_emailStatusError)
        setEmailStatusLoading(_emailStatusLoading)
        setEmailStatusResult(_emailStatusResult)
    }

    return (
        <>
            <Label>{props.t("Current report")}</Label>
            <MailingResult
                result={emailStatusResult}
                isSending={emailStatusLoading}
                error={emailStatusError}
                t={props.t}
            />
            <DatatableTables
                data={data}
                info={info}
                title={props.t("Device status report")}
                t={props.t}
                mailHandler={triggerMailing}
                mailUrl={`/api/email-status`}
            />
        </>
    )
}

const Reports = (props: any) => {
    const subTitle = props.t("Reports")
    document.title = `${subTitle} | ${props.t("TITLE")}`

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={subTitle}
                    breadcrumbItem={props.t("Operational")}
                />
                <ReportsContent t={props.t} />
            </Container>
        </div>
    )
}

export default withTranslation()(Reports)
