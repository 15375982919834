import React from "react"

import {
    Row,
    Col,
    Container,
    Form,
    Input,
    FormFeedback,
    Label,
} from "reactstrap"

import PropTypes from "prop-types"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"
import Copyright from "../../components/Common/Copyright"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, resetLoginFlag } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-sm.svg"

import CarouselPage from "../AuthenticationInner/CarouselPage"

//i18n
import { withTranslation } from "react-i18next"

const Login = (props: any) => {
    const dispatch = useDispatch()

    setTimeout(() => {
        dispatch(resetLoginFlag())
    }, 3000)

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required(props.t("EmailValidation")),
            password: Yup.string().required(props.t("PasswordValidation")),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate))
        },
    })

    const { error } = useSelector((state: any) => ({
        error: state.login.error,
    }))

    document.title = `Login | ${props.t("TITLE")}`

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link
                                                to="/dashboard"
                                                className="d-block auth-logo"
                                            >
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    height="28"
                                                />{" "}
                                                <span className="logo-txt">
                                                    {props.t("MainLogoTitle")}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">
                                                    {props.t("Welcome")}
                                                </h5>
                                                <p className="text-muted mt-2">
                                                    {props.t("SignInHint")}
                                                </p>
                                            </div>
                                            <Form
                                                className="custom-form mt-4 pt-2"
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    validation.handleSubmit()
                                                    return false
                                                }}
                                            >
                                                {error ? (
                                                    <div className="text-danger">
                                                        {props.t(error)}
                                                    </div>
                                                ) : null}
                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        {props.t("Email")}
                                                    </Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder={props.t(
                                                            "EnterEmail"
                                                        )}
                                                        type="email"
                                                        onChange={
                                                            validation.handleChange
                                                        }
                                                        onBlur={
                                                            validation.handleBlur
                                                        }
                                                        value={
                                                            validation.values
                                                                .email || ""
                                                        }
                                                        invalid={
                                                            validation.touched
                                                                .email &&
                                                            validation.errors
                                                                .email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.email &&
                                                    validation.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {
                                                                validation
                                                                    .errors
                                                                    .email
                                                            }
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        {props.t("Password")}
                                                    </Label>
                                                    <Input
                                                        name="password"
                                                        value={
                                                            validation.values
                                                                .password || ""
                                                        }
                                                        type="password"
                                                        placeholder={props.t(
                                                            "EnterPassword"
                                                        )}
                                                        onChange={
                                                            validation.handleChange
                                                        }
                                                        onBlur={
                                                            validation.handleBlur
                                                        }
                                                        invalid={
                                                            validation.touched
                                                                .password &&
                                                            validation.errors
                                                                .password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched
                                                        .password &&
                                                    validation.errors
                                                        .password ? (
                                                        <FormFeedback type="invalid">
                                                            {
                                                                validation
                                                                    .errors
                                                                    .password
                                                            }
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col">
                                                        <div className="mt-3 d-grid">
                                                            <button
                                                                className="btn btn-primary btn-block"
                                                                type="submit"
                                                            >
                                                                {props.t(
                                                                    "Login"
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <Copyright />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(withRouter(Login))

Login.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
}
